import { useLanguageByIp } from '@/hooks/useLanguageByIp'
import { Box, Grid, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { findLastIndex } from 'lodash'
import React, { useState } from 'react'

interface Props {
  locales: Record<string, any>
}

const getCurrentGroupIndex = ({ count, index }) => {
  const groupIndex = Math.floor(index / count)
  return Array.from({ length: count }, (_, i) => i + groupIndex * count)
}

export default (props: Props) => {
  const { locales } = props
  const theme = useTheme()
  const { direction } = theme
  const { lang } = useLanguageByIp()

  // 判断一排有几个盒子
  const ONE_COUNT = useMediaQuery('@media (min-width: 0px)')
  const ONE_COUNT1 = useMediaQuery('@media (min-width: 600px)')
  const TWO_COUNT = useMediaQuery('@media (min-width: 800px)')
  const THREE_COUNT = useMediaQuery('@media (min-width: 1280px)')

  const count = findLastIndex([ONE_COUNT1, TWO_COUNT, THREE_COUNT], (a) => !!a) + 1 || 1
  console.log({ count })

  const BoxWrapperSx = (expand) => {
    return {
      '--width': ['255px', '320px', '340px', '370px', '400px'],
      '--height': expand
        ? ['305px', '340px', '428px', '495px']
        : ['207px', '276px', '336px', '356px'],
      position: 'relative',
      width: 'var(--width)',
      minHeight: 'var(--height)',
      maxHeight: 'var(--height)',
      borderRadius: ['20px', '32px'],
      bgcolor: '#fff',
      mb: ['30px'],
      mx: '10px',
      boxShadow: '0px 6px 20px 0px rgba(0, 0, 0, 0.06)',
      '&:after': {
        content: '""',
        position: 'absolute',
        border: '4px solid #095ef7',
        minWidth: 'var(--width)',
        minHeight: 'var(--height)',
        maxHeight: 'var(--height)',
        borderRadius: ['20px', '32px'],
        left: '10px',
        top: '-9px',
        zIndex: -1,
      },
      '.img': {
        position: 'absolute',
      },
    }
  }

  const Title = (tit) => {
    return (
      <Box
        component={'h3'}
        sx={{
          position: 'absolute',
          top: ['20px', '60px'],
          fontSize: ['19px', 22, 24, 26, 28],
          fontWeight: 600,
          color: '#000417',
          textAlign: 'center',
          zIndex: 1,
          whiteSpace: 'pre-line',
        }}
      >
        {tit}
      </Box>
    )
  }
  const Content = (desc, expand?) => {
    return (
      <Box
        sx={{
          position: 'absolute',
          top: ['100px', '180px'],
          fontSize: ['14px', 18, 20, 21],
          fontWeight: 400,
          color: '#5b5c73',
          textAlign: 'center',
          zIndex: 1,
          whiteSpace: 'pre-line',
          ...(!expand
            ? {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                '-webkit-line-clamp': '4',
                '-webkit-box-orient': 'vertical',
              }
            : {}),
        }}
      >
        {desc}
      </Box>
    )
  }

  const GridWrapper = ({ children }) => {
    return (
      <Grid
        item
        xs={10}
        sm={8}
        md={6}
        lg={4}
        xl={4}
        sx={{ display: 'flex', justifyContent: 'center', position: 'relative' }}
      >
        {children}
      </Grid>
    )
  }

  const [status, setStatus] = useState({})

  return (
    <Grid
      container
      columns={{ xs: 10, sm: 8, md: 12, lg: 12, xl: 12 }}
      sx={{
        justifyContent: 'center',
        '.expand_svg': {
          cursor: 'pointer',
          '--size': ['24px', '37px', '44px'],
          width: 'var(--size)',
          height: 'var(--size)',
          marginInlineStart: 'auto',
          position: 'absolute',
          right: [10, '20px'],
          top: [11, '17px'],
          zIndex: 1,
        },
      }}
    >
      {/* en、ar 0 */}
      {!['zh', 'es-MX'].includes(lang) && (
        <GridWrapper>
          {/* 第一个盒子 */}
          <Box
            sx={{
              ...BoxWrapperSx(status[0]),
              '.img1': {
                left: 0,
                bottom: 0,
                width: [47, 51, 70, 68],
              },
              '.img2': {
                right: 0,
                bottom: 0,
                width: [44, 66, 94, 88],
                zIndex: 1,
              },
            }}
          >
            {!status[0] ? (
              <Box
                key={'1'}
                component='img'
                src='/images/zhankai.svg'
                className='expand_svg'
                onClick={() => {
                  setStatus((s) => {
                    getCurrentGroupIndex({ index: 0, count }).forEach((i) => {
                      s[i] = !s[i]
                    })
                    return { ...s }
                  })
                }}
              />
            ) : (
              <Box
                key={'1'}
                component='img'
                className='expand_svg'
                src='/images/shouqi.svg'
                onClick={() => {
                  setStatus((s) => {
                    getCurrentGroupIndex({ index: 0, count }).forEach((i) => {
                      s[i] = !s[i]
                    })
                    return { ...s }
                  })
                }}
              />
            )}
            <img
              src={'https://imile-minio-cdn.imile.com/imile-home/offical-web/service/1_1@2x.png'}
              className='img img1'
            />
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              {Title(locales.content1[0].split('&&')[0])}
              {Content(locales.content1[0].split('&&')[1], status[0])}
            </Box>
          </Box>
        </GridWrapper>
      )}

      {/* en、ar 1 */}
      {/* zh、mx 0 */}
      {/* 第二个盒子 */}
      <GridWrapper>
        <Box
          sx={{
            ...BoxWrapperSx(status[['zh', 'es-MX'].includes(lang) ? 0 : 1]),
            '.img1': {
              right: ['-86px', '-104px', '-126px'],
              bottom: ['-14px', '-20px'],
              width: [155, 188, 220],
              zIndex: 1,
            },
            // ml: [0, '30px'],
          }}
        >
          {!status[['zh', 'es-MX'].includes(lang) ? 0 : 1] ? (
            <Box
              component='img'
              src='/images/zhankai.svg'
              className='expand_svg'
              onClick={() => {
                setStatus((s) => {
                  getCurrentGroupIndex({
                    index: ['zh', 'es-MX'].includes(lang) ? 0 : 1,
                    count,
                  }).forEach((i) => {
                    s[i] = !s[i]
                  })
                  return { ...s }
                })
              }}
            />
          ) : (
            <Box
              component='img'
              className='expand_svg'
              src='/images/shouqi.svg'
              onClick={() => {
                setStatus((s) => {
                  getCurrentGroupIndex({
                    index: ['zh', 'es-MX'].includes(lang) ? 0 : 1,
                    count,
                  }).forEach((i) => {
                    s[i] = !s[i]
                  })
                  return { ...s }
                })
              }}
            />
          )}
          <img
            src='https://imile-minio-cdn.imile.com/imile-home/offical-web/home/what_we_do2_2.png'
            alt='iMile ECommerce Delivery Solutions'
            className='img img1'
          />
          {/* <StaticImage
            src='../../../static/images/home/what_we_do2_2.png'
            alt='iMile ECommerce Delivery Solutions'
            placeholder='blurred'
            objectFit='cover'
            className='img img1'
          /> */}
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {Title(locales.content1[1].split('&&')[0])}
            {Content(
              locales.content1[1].split('&&')[1],
              status[['zh', 'es-MX'].includes(lang) ? 0 : 1],
            )}
          </Box>
        </Box>
      </GridWrapper>

      {/* zh 1 */}
      {/* 第3个盒子  Airway Parcel Delivery */}
      {['zh'].includes(lang) && (
        <GridWrapper>
          <Box
            sx={{
              ...BoxWrapperSx(status[1]),
              '.img2': {
                right: [-45, -82, -67, -72, -75],
                top: ['-19px', '-35px'],
                width: [102, 200, 203, 210, 187],
                zIndex: 1,
              },
              // ml: [0, 0, '30px', '30px'],
            }}
          >
            {!status[1] ? (
              <Box
                component='img'
                src='/images/zhankai.svg'
                className='expand_svg'
                onClick={() => {
                  setStatus((s) => {
                    getCurrentGroupIndex({
                      index: 1,
                      count,
                    }).forEach((i) => {
                      s[i] = !s[i]
                    })
                    return { ...s }
                  })
                }}
              />
            ) : (
              <Box
                component='img'
                className='expand_svg'
                src='/images/shouqi.svg'
                onClick={() => {
                  setStatus((s) => {
                    getCurrentGroupIndex({ index: 1, count }).forEach((i) => {
                      s[i] = !s[i]
                    })
                    return { ...s }
                  })
                }}
              />
            )}
            <img src='/images/home/air.png' className='img img2' />
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              {Title(locales.content1[8].split('&&')[0])}
              {Content(locales.content1[8].split('&&')[1], status[1])}
            </Box>
          </Box>
        </GridWrapper>
      )}

      {/* en、ar 2 */}
      {/* mx 1 */}
      {/* 第三个盒子 Same Day Next Day */}
      {!['zh'].includes(lang) && (
        <GridWrapper>
          <Box
            sx={{
              ...BoxWrapperSx(status[['es-MX'].includes(lang) ? 1 : 2]),
              '.img2': {
                left: direction === 'rtl' ? [-45, -82, -67, -72, -75] : 'unset',
                right: direction === 'ltr' ? [-45, -82, -67, -72, -75] : 'unset',
                transform: direction === 'rtl' ? 'scaleX(-1)' : 'unset',
                // right: [-45, -82, -67, -72, -75],
                bottom: ['-19px', '-35px'],
                width: [102, 200, 203, 210, 187],
                zIndex: 1,
              },
              // ml: [0, 0, '30px', '30px'],
            }}
          >
            {!status[['es-MX'].includes(lang) ? 1 : 2] ? (
              <Box
                component='img'
                src='/images/zhankai.svg'
                className='expand_svg'
                onClick={() => {
                  setStatus((s) => {
                    getCurrentGroupIndex({
                      index: ['es-MX'].includes(lang) ? 1 : 2,
                      count,
                    }).forEach((i) => {
                      s[i] = !s[i]
                    })
                    return { ...s }
                  })
                }}
              />
            ) : (
              <Box
                component='img'
                className='expand_svg'
                src='/images/shouqi.svg'
                onClick={() => {
                  setStatus((s) => {
                    getCurrentGroupIndex({
                      index: ['es-MX'].includes(lang) ? 1 : 2,
                      count,
                    }).forEach((i) => {
                      s[i] = !s[i]
                    })
                    return { ...s }
                  })
                }}
              />
            )}
            <img
              src={'https://imile-minio-cdn.imile.com/imile-home/offical-web/img/what_we_do3.png'}
              className='img img2'
            />
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              {Title(locales.content1[2].split('&&')[0])}
              {Content(
                locales.content1[2].split('&&')[1],
                status[['es-MX'].includes(lang) ? 1 : 2],
              )}
            </Box>
          </Box>
        </GridWrapper>
      )}

      {/* en ar 3 */}
      {/* zh mx 2 */}
      {/* 第四个盒子 */}
      <GridWrapper>
        <Box
          sx={{
            ...BoxWrapperSx(status[['zh', 'es-MX'].includes(lang) ? 2 : 3]),
            '.img1': {
              left: 0,
              top: 0,
              width: [102, 126, 157, 155],
            },
            // ml: [0, '30px', '30px', 0]
          }}
        >
          {!status[['zh', 'es-MX'].includes(lang) ? 2 : 3] ? (
            <Box
              component='img'
              src='/images/zhankai.svg'
              className='expand_svg'
              onClick={() => {
                setStatus((s) => {
                  getCurrentGroupIndex({
                    index: ['zh', 'es-MX'].includes(lang) ? 2 : 3,
                    count,
                  }).forEach((i) => {
                    s[i] = !s[i]
                  })
                  return { ...s }
                })
              }}
            />
          ) : (
            <Box
              component='img'
              className='expand_svg'
              src='/images/shouqi.svg'
              onClick={() => {
                // 收起
                setStatus((s) => {
                  getCurrentGroupIndex({
                    index: ['zh', 'es-MX'].includes(lang) ? 2 : 3,
                    count,
                  }).forEach((i) => {
                    s[i] = !s[i]
                  })
                  return { ...s }
                })
              }}
            />
          )}
          <img
            src='https://imile-minio-cdn.imile.com/imile-home/offical-web/img/4_1@2x.png'
            className='img img1'
          />
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {Title(locales.content1[3].split('&&')[0])}
            {Content(
              locales.content1[3].split('&&')[1],
              status[['zh', 'es-MX'].includes(lang) ? 2 : 3],
            )}
          </Box>
        </Box>
      </GridWrapper>

      {/* en ar 4 */}
      {/* zh mx 3 */}
      {/* 第五个盒子 */}
      <GridWrapper>
        <Box
          sx={{
            ...BoxWrapperSx(status[['zh', 'es-MX'].includes(lang) ? 3 : 4]),
            '.img1': {
              right: [-46, -66, -82],
              bottom: [-10, -13],
              width: [87, 114, 132, 132, 147],
              zIndex: 1,
            },
            // ml: [0, '30px'],
          }}
        >
          {!status[['zh', 'es-MX'].includes(lang) ? 3 : 4] ? (
            <Box
              component='img'
              src='/images/zhankai.svg'
              className='expand_svg'
              onClick={() => {
                setStatus((s) => {
                  getCurrentGroupIndex({
                    index: ['zh', 'es-MX'].includes(lang) ? 3 : 4,
                    count,
                  }).forEach((i) => {
                    s[i] = !s[i]
                  })
                  return { ...s }
                })
              }}
            />
          ) : (
            <Box
              component='img'
              className='expand_svg'
              src='/images/shouqi.svg'
              onClick={() => {
                // 收起
                setStatus((s) => {
                  getCurrentGroupIndex({
                    index: ['zh', 'es-MX'].includes(lang) ? 3 : 4,
                    count,
                  }).forEach((i) => {
                    s[i] = !s[i]
                  })
                  return { ...s }
                })
              }}
            />
          )}
          <img
            src='https://imile-minio-cdn.imile.com/imile-home/offical-web/service/5_1@2x.png'
            className='img img1'
          />
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {Title(locales.content1[4].split('&&')[0])}
            {Content(
              locales.content1[4].split('&&')[1],
              status[['zh', 'es-MX'].includes(lang) ? 3 : 4],
            )}
          </Box>
        </Box>
      </GridWrapper>

      {/* en ar 5 */}
      {/* zh mx 4 */}
      {/* 第六个盒子 */}
      <GridWrapper>
        <Box
          sx={{
            ...BoxWrapperSx(status[['zh', 'es-MX'].includes(lang) ? 4 : 5]),
            '.img1': {
              bottom: 0,
              width: [142, 180, 190, 196, 202],
              left: [18, '50px'],
              top: [36, '57px'],
            },
            // ml: [0, '30px'],
          }}
        >
          {!status[['zh', 'es-MX'].includes(lang) ? 4 : 5] ? (
            <Box
              component='img'
              src='/images/zhankai.svg'
              className='expand_svg'
              onClick={() => {
                // 展开
                setStatus((s) => {
                  getCurrentGroupIndex({
                    index: ['zh', 'es-MX'].includes(lang) ? 4 : 5,
                    count,
                  }).forEach((i) => {
                    s[i] = !s[i]
                  })
                  return { ...s }
                })
              }}
            />
          ) : (
            <Box
              component='img'
              className='expand_svg'
              src='/images/shouqi.svg'
              onClick={() => {
                // 收起
                setStatus((s) => {
                  getCurrentGroupIndex({
                    index: ['zh', 'es-MX'].includes(lang) ? 4 : 5,
                    count,
                  }).forEach((i) => {
                    s[i] = !s[i]
                  })
                  return { ...s }
                })
              }}
            />
          )}
          <img
            src='https://imile-minio-cdn.imile.com/imile-home/offical-web/img/6_1@2x.png'
            className='img img1'
          />
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {Title(locales.content1[5].split('&&')[0])}
            {Content(
              locales.content1[5].split('&&')[1],
              status[['zh', 'es-MX'].includes(lang) ? 4 : 5],
            )}
          </Box>
        </Box>
      </GridWrapper>

      {/* en ar 6 */}
      {/* zh mx 5 */}
      {/* 第七个盒子 */}
      <GridWrapper>
        <Box
          sx={{
            ...BoxWrapperSx(status[['zh', 'es-MX'].includes(lang) ? 5 : 6]),
            '.img1': {
              right: 10,
              bottom: 7,
              width: [42, 42, 44, 54, 64],
            },
            // ml: [0, '30px'],
          }}
        >
          {!status[['zh', 'es-MX'].includes(lang) ? 5 : 6] ? (
            <Box
              component='img'
              src='/images/zhankai.svg'
              className='expand_svg'
              onClick={() => {
                // 展开
                setStatus((s) => {
                  getCurrentGroupIndex({
                    index: ['zh', 'es-MX'].includes(lang) ? 5 : 6,
                    count,
                  }).forEach((i) => {
                    s[i] = !s[i]
                  })
                  return { ...s }
                })
              }}
            />
          ) : (
            <Box
              component='img'
              className='expand_svg'
              src='/images/shouqi.svg'
              onClick={() => {
                // 收起
                setStatus((s) => {
                  getCurrentGroupIndex({
                    index: ['zh', 'es-MX'].includes(lang) ? 5 : 6,
                    count,
                  }).forEach((i) => {
                    s[i] = !s[i]
                  })
                  return { ...s }
                })
              }}
            />
          )}
          <img
            src='https://imile-minio-cdn.imile.com/imile-home/offical-web/service/7_1@2x.png'
            className='img img1'
          />
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {Title(locales.content1[6].split('&&')[0])}
            {Content(
              locales.content1[6].split('&&')[1],
              status[['zh', 'es-MX'].includes(lang) ? 5 : 6],
            )}
          </Box>
        </Box>
      </GridWrapper>

      {/* zh mx 6 */}
      {/* 第八个盒子 Document Deliveries */}
      {['zh', 'es-MX'].includes(lang) && (
        <GridWrapper>
          <Box
            sx={{
              ...BoxWrapperSx(status[6]),
              '.img1': {
                bottom: [-14, '-27px'],
                width: [81, 127, 124, 142],
                right: ['-40px', '-76px'],
              },
              // ml: [0, '30px'],
            }}
          >
            {!status[6] ? (
              <Box
                component='img'
                src='/images/zhankai.svg'
                className='expand_svg'
                onClick={() => {
                  // 展开
                  setStatus((s) => {
                    getCurrentGroupIndex({
                      index: 6,
                      count,
                    }).forEach((i) => {
                      s[i] = !s[i]
                    })
                    return { ...s }
                  })
                }}
              />
            ) : (
              <Box
                component='img'
                className='expand_svg'
                src='/images/shouqi.svg'
                onClick={() => {
                  // 收起
                  setStatus((s) => {
                    getCurrentGroupIndex({
                      index: 6,
                      count,
                    }).forEach((i) => {
                      s[i] = !s[i]
                    })
                    return { ...s }
                  })
                }}
              />
            )}
            <img
              src='https://imile-minio-cdn.imile.com/imile-home/offical-web/service/8_1@2x.png'
              className='img img1'
            />
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              {Title(locales.content1[7].split('&&')[0])}
              {Content(locales.content1[7].split('&&')[1], status[6])}
            </Box>
          </Box>
        </GridWrapper>
      )}
    </Grid>
  )
}
