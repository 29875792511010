import { Helmet } from '@/components'
import { Box, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { graphql } from 'gatsby'
import { useRequest } from 'ahooks'
import React, { useLayoutEffect } from 'react'
import { APIqueryBizDictData, APIqueryCountry } from '@/components/EnquireForm/api'
import { Footer, Header, SEO } from '../../components'
import EnquireFromCompotent from '../../components/EnquireForm/index'
import ServiceWhatWeDo from '../../components/ServiceWhatWeDo'
import Title from '../../components/Title'

export default (props) => {
  const theme = useTheme()
  const { direction } = theme
  const pageLocale = JSON.parse(props.data.locales.edges[0].node.data)
  const seoLocale = JSON.parse(props.data.seoLocale.data)
  const homeLocale = JSON.parse(props.data.homeLocale.data)
  const isPc = useMediaQuery('@media(min-width: 800px)')
  const dictRes = useRequest(() => APIqueryBizDictData('NumberofDailyOrders,WebCountry'))
  const countryList = dictRes?.data?.resultObject?.WebCountry || []
  const dailyCountList = dictRes.data?.resultObject?.NumberofDailyOrders || []
  const callCodeRes = useRequest(APIqueryCountry)
  const callCodeList = callCodeRes?.data?.resultObject || []

  const SupportWrapper = {
    display: 'flex',
    height: [320],
    borderRadius: ['40px'],
  }

  const SupportWrapperMobile = {
    display: 'flex',
    justifyContent: 'center',
    height: [176, 296],
    mb: '30px',
    '.bg-img': {
      width: [148, 296],
    },
  }

  const SupportBox = (props) => {
    const { title, desc, sx } = props
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          bgcolor: '#f6f7fa',
          p: ['16px', '40px'],
          maxWidth: ['227px', '280px', '300px', '338px', '340px'],
          '.tit': {
            color: '#000417',
            fontSize: [16, 22],
            fontWeight: 600,
            mb: ['10px', '20px'],
          },
          '.desc': {
            color: '#797a8d',
            fontSize: [12, 16],
          },
          ...sx,
        }}
      >
        <h3 className='tit'>{title}</h3>
        <div className='desc'>{desc}</div>
      </Box>
    )
  }

  useLayoutEffect(() => {
    document.querySelector('.banner')?.scrollIntoView({})
  }, [])

  return (
    <>
      <SEO
        title={seoLocale.whatWeDo.title}
        description={seoLocale.whatWeDo.description}
        pageLocale={pageLocale}
      />
      <Helmet>
        <meta
          name='keywords'
          content='Cross-border shipping services, Cross-border delivery, Warehousing services, Warehousing solutions and management, Last mile delivery solutions, Order fulfillment services'
        />

        <meta property='og:type' content='website' />
        <meta property='og:site_name' content='iMile Delivery' />
        <meta property='og:url' content='https://www.imile.com/service/whatWeDo' />
        <meta
          property='og:image'
          content='https://www.imile.com/static/675c7f460f69d05af79243fa8244be7b/56c4d/banner.webp'
        />
        <meta
          property='og:title'
          content='Last-Mile, First-Mile & Fulfillment eCommerce Delivery Services'
        />
        <meta
          property='og:description'
          content="Are you looking for eCommerce delivery solutions? iMile's team of experts specializes in eCommerce, express delivery, last-mile, custom clearance, cross-border solutions, warehousing, and fulfillment."
        />
      </Helmet>
      <Header
        location={props.location}
        footerLocale={JSON.parse(props.data.footerLocale.data)}
        headerLocale={JSON.parse(props.data.headerLocale.data)}
        languageLocale={JSON.parse(props.data.languageLocale.data)}
      />

      {/* banner */}
      <Box
        className='banner'
        sx={{
          position: 'relative',
          '.bg-img': {
            height: ['100vh', '100vh', '650px', '790px'],
            width: 1,
          },
        }}
      >
        {isPc ? (
          <img
            src='https://imile-minio-cdn.imile.com/imile-home/offical-web/homepage/banner.png'
            alt='About iMile Delivery'
            className='bg-img'
          />
        ) : (
          <img
            src='https://imile-minio-cdn.imile.com/imile-home/offical-web/homepage/banner_mobile.png'
            alt='About iMile Delivery'
            className='bg-img'
          />
        )}
        {/* {isPc ? (
          <StaticImage
            src='../../../static/images/service/banner.png'
            alt='About iMile Delivery'
            objectFit='cover'
            loading='lazy'
            placeholder='blurred'
            className='bg-img'
          />
        ) : (
          <StaticImage
            src='../../../static/images/service/banner_mobile.png'
            alt='About iMile Delivery'
            objectFit='cover'
            loading='lazy'
            placeholder='blurred'
            className='bg-img'
          />
        )} */}
        <Box
          sx={{
            position: 'absolute',
            top: ['25%', '25%', '32%'],
            transform: 'translate(0, -40%)',
            right: ['16px', '34px', '80px'],
            color: '#fff',
            textAlign: 'right',
            marginInlineStart: 'auto',
            whiteSpace: 'pre-line',
            '.tit': {
              marginInlineStart: 'auto',
              fontSize: [24, 30, 35, 35, 40],
              fontWeight: 600,
              mb: '10px',
            },
            '.desc': {
              fontSize: [14, 18, 22, 22, 28],
            },
          }}
        >
          <div className='tit'>{pageLocale.Slogan}</div>
          <div className='desc'>{pageLocale.SloganDesc}</div>
        </Box>
      </Box>

      {/* what we do */}
      <Box
        sx={{
          px: 'var(--page-px)',
          maxWidth: 'var(--page-width)',
          margin: '0 auto',
        }}
      >
        <Title title={pageLocale.title1} component='h2' />
        <ServiceWhatWeDo locales={pageLocale} />
      </Box>

      {/* contact us */}
      <Box
        sx={{
          width: ' 100vw',
          '--height': ['108px', '164px'],
          height: 'var(--height)',
          lineHeight: isPc ? 'var(--height)' : ['54px', '88px'],
          background: '#e6e6ef',
          mt: ['45px', '90px'],
        }}
      >
        <Box
          sx={{
            px: 'var(--page-px)',
            maxWidth: 'var(--page-width)',
            margin: '0 auto',
            display: 'flex',
            flexDirection: isPc ? 'row' : 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
            fontWeight: 600,
            fontSize: [16, 24, 28, 30, 32],
            color: '#000',
          }}
        >
          <div>{pageLocale.ContactUsDesc}</div>
          {/* <div className='btn'>{pageLocale.ContactUs}</div> */}
          <EnquireFromCompotent
            locale={homeLocale}
            footerLocale={JSON.parse(props.data.footerLocale.data)}
            btnTxt={pageLocale.ContactUs}
            btnSx={{
              '--btn-height': ['36px', '54px', '58px', '66px', '76px'],
              height: 'var(--btn-height)',
              lineHeight: 'var(--btn-height)',
              borderRadius: ['38px'],
              p: ['0 20px', '0 40px'],
              color: '#fff',
              bgcolor: '#095ef7',
              marginInlineStart: isPc ? 'auto' : 'unset',
              cursor: 'pointer',
            }}
            countryList={countryList}
            dailyCountList={dailyCountList}
            callCodeList={callCodeList}
          />
        </Box>
      </Box>

      {/* Who We Support */}
      <Box
        sx={{
          px: [0, 0, 'var(--page-px)'],
          maxWidth: 'var(--page-width)',
          margin: '0 auto',
        }}
      >
        <Title title={pageLocale.title2} component='h2' />

        {isPc ? (
          <Box>
            <Box
              className='first'
              sx={{
                ...SupportWrapper,
                mb: ['60px'],
                '.bg-img1': {
                  transform: direction === 'rtl' ? 'scaleX(-1)' : '',
                },
                '.bg-img2': {},
              }}
            >
              <img
                src='https://imile-minio-cdn.imile.com/imile-home/offical-web/service/support1@2x.png'
                alt='About iMile Delivery'
                className='bg-img1'
              />
              {/* <StaticImage
                src='https://imile-minio-cdn.imile.com/imile-home/offical-web/service/support1@2x.png'
                alt='About iMile Delivery'
                objectFit='cover'
                loading='lazy'
                placeholder='blurred'
                className='bg-img1'
              /> */}
              <SupportBox
                title={pageLocale.content3[0].split('&&')[0]}
                desc={pageLocale.content3[0].split('&&')[1]}
              />
              <img
                src='https://imile-minio-cdn.imile.com/imile-home/offical-web/service/support2@2x.png'
                alt='About iMile Delivery'
                className='bg-img2'
              />
              {/* <StaticImage
                src='../../../static/images/service/support2@2x.png'
                alt='About iMile Delivery'
                objectFit='cover'
                loading='lazy'
                placeholder='blurred'
                className='bg-img2'
              /> */}
              <SupportBox
                title={pageLocale.content3[1].split('&&')[0]}
                desc={pageLocale.content3[1].split('&&')[1]}
                sx={{
                  borderRadius: direction === 'ltr' ? ['0 40px 40px 0'] : ['40px 0 0 40px'],
                }}
              />
            </Box>
            <Box
              className='second'
              sx={{
                ...SupportWrapper,
                '.bg-img1': {
                  transform: direction === 'rtl' ? 'scaleX(-1)' : '',
                },
              }}
            >
              <SupportBox
                title={pageLocale.content3[2].split('&&')[0]}
                desc={pageLocale.content3[2].split('&&')[1]}
                sx={{
                  borderRadius: direction === 'rtl' ? ['0 40px 40px 0'] : ['40px 0 0 40px'],
                }}
              />
              <img
                src='https://imile-minio-cdn.imile.com/imile-home/offical-web/service/support3@2x.png'
                alt='About iMile Delivery'
                className='bg-img2'
              />
              {/* <StaticImage
                src='../../../static/images/service/support3@2x.png'
                alt='About iMile Delivery'
                objectFit='cover'
                loading='lazy'
                placeholder='blurred'
                className='bg-img2'
              /> */}
              <SupportBox
                title={pageLocale.content3[3].split('&&')[0]}
                desc={pageLocale.content3[3].split('&&')[1]}
              />
              <img
                src='https://imile-minio-cdn.imile.com/imile-home/offical-web/service/support4@2x.png'
                alt='About iMile Delivery'
                className='bg-img1'
              />
              {/* <StaticImage
                src='../../../static/images/service/support4@2x.png'
                alt='About iMile Delivery'
                objectFit='cover'
                loading='lazy'
                placeholder='blurred'
                className='bg-img1'
              /> */}
            </Box>
          </Box>
        ) : (
          <Box>
            <Box sx={{ ...SupportWrapperMobile }}>
              <img
                src='https://imile-minio-cdn.imile.com/imile-home/offical-web/service/support1@2x.png'
                alt='About iMile Delivery'
                className='bg-img'
              />
              {/* <StaticImage
                src='../../../static/images/service/support1@2x.png'
                alt='About iMile Delivery'
                objectFit='cover'
                loading='lazy'
                placeholder='blurred'
                className='bg-img'
              /> */}
              <SupportBox
                title={pageLocale.content3[0].split('&&')[0]}
                desc={pageLocale.content3[0].split('&&')[1]}
              />
            </Box>
            <Box sx={{ ...SupportWrapperMobile }}>
              <SupportBox
                title={pageLocale.content3[1].split('&&')[0]}
                desc={pageLocale.content3[1].split('&&')[1]}
              />
              <img
                src='https://imile-minio-cdn.imile.com/imile-home/offical-web/service/support2@2x.png'
                alt='About iMile Delivery'
                className='bg-img'
              />
              {/* <StaticImage
                src='../../../static/images/service/support2@2x.png'
                alt='About iMile Delivery'
                objectFit='cover'
                loading='lazy'
                placeholder='blurred'
                className='bg-img'
              /> */}
            </Box>
            <Box sx={{ ...SupportWrapperMobile }}>
              <img
                src='https://imile-minio-cdn.imile.com/imile-home/offical-web/service/support3@2x.png'
                alt='About iMile Delivery'
                className='bg-img'
              />
              {/* <StaticImage
                src='../../../static/images/service/support3@2x.png'
                alt='About iMile Delivery'
                objectFit='cover'
                loading='lazy'
                placeholder='blurred'
                className='bg-img'
              /> */}
              <SupportBox
                title={pageLocale.content3[2].split('&&')[0]}
                desc={pageLocale.content3[2].split('&&')[1]}
              />
            </Box>
            <Box sx={{ ...SupportWrapperMobile }}>
              <SupportBox
                title={pageLocale.content3[3].split('&&')[0]}
                desc={pageLocale.content3[3].split('&&')[1]}
              />
              <img
                src='https://imile-minio-cdn.imile.com/imile-home/offical-web/service/support4@2x.png'
                alt='About iMile Delivery'
                className='bg-img'
              />
              {/* <StaticImage
                src='../../../static/images/service/support4@2x.png'
                alt='About iMile Delivery'
                objectFit='cover'
                loading='lazy'
                placeholder='blurred'
                className='bg-img'
              /> */}
            </Box>
          </Box>
        )}
      </Box>

      {/* Why iMile? */}
      <Box
        sx={{
          px: 'var(--page-px)',
          maxWidth: 'var(--page-width)',
          margin: '0 auto',
        }}
      >
        <Title title={pageLocale.title3} component='h2' />

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-evenly',
            flexWrap: 'wrap',
            mb: ['30px', '120px'],
          }}
        >
          {pageLocale.content2.map((item, index) => {
            return (
              <Box
                sx={{
                  position: 'relative',
                  textAlign: 'center',
                  flex: [, , 1],
                  mx: ['5px', '5px', '10px', '15px'],
                  maxWidth: ['255px', '380px'],
                  height: ['231px', '342px'],
                  color: '#fff',
                  background: '#095ef7',
                  borderRadius: '32px',
                  mb: ['30px'],
                  ':after': {
                    content: '""',
                    position: 'absolute',
                    top: '10px',
                    left: '-10px',
                    width: '100%',
                    height: '100%',
                    border: '4px solid #fff303',
                    borderRadius: '32px',
                  },
                  p: ['30px 24px', '60px 32px', '60px 32px', '40px 22px', '50px 30px'],
                  '.tit': {
                    height: ['81px'],
                    fontSize: [19, 26, 26, 26, 28],
                    fontWeight: 600,
                    margin: '0 auto',
                    mb: ['20px', '38px'],
                    whiteSpace: 'pre-line',
                  },
                  '.desc': {
                    fontSize: [14, 16, 18, 22],
                  },
                }}
              >
                <div className='tit'>{item.split('&&')[0]}</div>
                <div className='desc'>{item.split('&&')[1]}</div>
              </Box>
            )
          })}
        </Box>
      </Box>

      <Footer
        footerLocale={JSON.parse(props.data.footerLocale.data)}
        languageLocale={JSON.parse(props.data.languageLocale.data)}
      />
    </>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { ns: { in: ["services"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
        }
      }
    }
    headerLocale: locale(ns: { eq: "header" }, language: { eq: $language }) {
      data
    }
    footerLocale: locale(ns: { eq: "footer" }, language: { eq: $language }) {
      data
    }
    languageLocale: locale(ns: { eq: "language" }, language: { eq: $language }) {
      data
    }
    seoLocale: locale(ns: { eq: "seo" }, language: { eq: $language }) {
      data
    }
    homeLocale: locale(ns: { eq: "home" }, language: { eq: $language }) {
      data
    }
  }
`
